import Calculator from "./component/Calculator"
import ContentBlock from "../../components/UI/ContentBlock"
import Title from "../../components/UI/Title"
import Description from "../../components/UI/Description"
import ReadyToStart from "../../components/UI/ReadyToStart"
import {useEffect} from "react"


const PriceScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Стоимость, тарифный калькулятор'
	}, [])

	return (
		<>
			<ContentBlock>
				<Title top={110}>Тарифный калькулятор</Title>

				<Description top={25}>
					Оплачивайте только за тот объем адресов, маршрутных листов и опций, которые необходимы именно вам.
				</Description>
				<Calculator/>
				<ReadyToStart/>
			</ContentBlock>
		</>
	)
}

export default PriceScreen


