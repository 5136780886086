import styled from "styled-components"

const Title = ({top = 0, children}) => {

	return (
		<Wrapper top={top}>
			{children}
		</Wrapper>
	)
}

export default Title

const Wrapper = styled.div`
  align-self: center;
  margin-top: ${props => props.top}px;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.15em;
  max-width: 700px;
  text-align: center;
`
