import styled from "styled-components"
import {Button, Space} from "antd"
import {Link} from "react-router-dom"

const ReadyToStart = ({
						  title,
						  description,
						  bright = true,
						  top = 110,
					  }) => {

	return (
		<Wrapper top={top} bright={bright}>
			<Title>Остались вопросы?</Title>
			<Description>
				Свяжитесь с нами, чтобы узнать больше.
			</Description>
			<ButtonsWrapper>
				<Space>
					<Link to={"/contact"}><Button shape="round" type="primary" size="large">Попробовать
						бесплатно</Button></Link>
					<Link to={"/contact"}><Button shape="round" size="large">Связаться с нами</Button></Link>
				</Space>
			</ButtonsWrapper>
		</Wrapper>
	)
}

export default ReadyToStart

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.top}px;
  margin-inline: 40px;
  align-items: center;
  background-color: ${props => props.bright ? props.theme.colorBgCard : " unset"};
  padding: 70px 60px;
  border-radius: 24px;
  background-image: radial-gradient(ellipse farthest-corner at 5px 5px, rgba(101, 67, 66, .15) 20%, rgba(80, 80, 80, 0.2));
`
const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
`
const Description = styled.p`
  max-width: 700px;
  text-align: center;
  margin-top: 25px;
  color: ${props => props.theme.colorTextDescription}
`
const ButtonsWrapper = styled.div`
  margin-top: 15px;

  button {
    min-width: 200px;
  }
`
