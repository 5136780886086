import Title from "../../components/UI/Title"
import ContentBlock from "../../components/UI/ContentBlock"
import Description from "../../components/UI/Description"
import {Button, Space} from "antd"
import styled from "styled-components"
import PartnerScreen from "./component/PartnerScreen"
import Carousel from "./component/Carousel"
import Card from "../../components/UI/Card"
import Icon from "../../components/Icon/Icon"
import {faArrowRight} from "@fortawesome/pro-regular-svg-icons"
import {faLeaf} from "@fortawesome/pro-solid-svg-icons"
import {Link, useNavigate} from "react-router-dom"
import ReadyToStart from "../../components/UI/ReadyToStart"

const HomeScreen = () => {

	const navigate = useNavigate()

	return (
		<>
			<Title top={120}>Цифровая трансформация вашей лостики</Title>

			<Description top={25}>
				Самые современные решения для оптимизации доставки, взаимодействия с водителями и повышения уровня
				клиентского сервиса.
				<ButtonWrapper>
					<Link to={"/contact"}><Button type="primary" shape="round" size="large">Попробовать
						бесплатно</Button></Link>
					<Link to={"/contact"}><Button shape="round" size="large">Связаться с нами</Button></Link>
				</ButtonWrapper>
			</Description>

			{/*<Carousel/>*/}

			<ContentBlock centered top={140}>
				<PartnerScreen/>
			</ContentBlock>

			<ContentBlock top={0}>
				<Card title="Мобильное приложение" top={0}
					  image="/assets/img/png/home/mobile-app.png"
				>
					<p>
						Самое совершенное и удобное мобильное приложение для водителя на рынке.
					</p>
					<p>
						Маршрутные листы, архив поездок, взаиморасчеты, штрафы ГИБДД — всё это и многое другое доступно
						водителю.
					</p>

					<Button shape="round" onClick={() => navigate('/mobile-app')}>Мобильное приложение <Icon
						name={faArrowRight} size={12} left={5}/></Button>
				</Card>

				<Card title="Мониторинг" right
					  badge={{text: <><Icon name={faLeaf} style={{marginRight: 5}}/> ECO Drive</>, color: '#7cb305'}}
					  image="/assets/img/png/home/monitoring.png">
					<p>
						Местоположение, время посещений, скоростной режим, контроль топлива — все в одном месте.
					</p>
					<p>
						Новая система ECO Drive поможет оценивать персональное качество и экономичность вождения.
					</p>

					<Button shape="round" onClick={() => navigate('/monitoring')}>Мониторинг <Icon name={faArrowRight}
																								   size={12} left={5}/></Button>
				</Card>

				<Card title="Маршрутизация"
					  image="/assets/img/png/home/routing.png">
					<p>
						Мощный и одновременно простой инструмент для маршрутизации точек.
					</p>
					<p>
						Фильтруй, выделяй и маршрутизируй точки прямо на карте, а система поможет подобрать водителя и
						транспортное средство.
					</p>
					<Button shape="round" onClick={() => navigate('/routing')}>Маршрутизация <Icon name={faArrowRight}
																								   size={12} left={5}/></Button>
				</Card>

				<Card mediaTransparent title="Приложение руководителя" right
					  image="/assets/img/png/home/manager-mobile-app.png">
					<p>
						Помимо стандартных отчётов, мы собираем персональные отчёты для ваших сотрудников с разными
						срезами
						данных.
					</p>
				</Card>

				<Card bright title="Штрафы ГИБДД" mediaTransparent badge={{color: '#007AFC', text: 'Новое'}}
					  image="/assets/img/png/home/fine.png">
					<p>
						Мы автоматически собираем штрафы из ГИБДД.
					</p>
					<p>
						Система сама определит водителя, который нарушил, и отправит ему уведомление в мобильное
						приложение.
					</p>
				</Card>

				{/*<Card title="Система мотивации водителей" right
					  image="https://www.efsoftware.com/as/s.png">
					<p>
						Мы обрабатываем огромное количество событий и показателей работы ваших водителей.
					</p>
					<p>
						Создавайте категории водителей с разными коэффициентами вознаграждений, а система автоматически
						будет их перемещать.
					</p>
					<a>Узнать больше <Icon name={faArrowRight}/></a>
				</Card>*/}
			</ContentBlock>

			<ContentBlock>
				<ReadyToStart/>
			</ContentBlock>
		</>
	)
}

export default HomeScreen

const ButtonWrapper = styled(Space)`
  margin-top: 32px;
  align-self: center;

  button {
    min-width: 230px;
  }
`
