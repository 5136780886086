import {Swiper, SwiperSlide} from 'swiper/react'
import {EffectCoverflow, Pagination, Navigation} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import styled from "styled-components"
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const Carousel = () => {

	const a = [1, 2, 3, 4, 5, 6, 7, 8]

	return (
		<Wrapper>
			<Swiper
				autoplay
				centeredSlides
				effect={'coverflow'}
				loop={true}
				slidesPerView={3}
				spaceBetween={10}
				coverflowEffect={{
					rotate: 0,
					stretch: 0,
					depth: 100,
					modifier: 2.5
				}}
				pagination={false}
				navigation={true}
				modules={[EffectCoverflow, Navigation]}
				onSlideChange={e => console.log(e)}
			>
				{a.map(item => (
					<SwiperItem key={"i" + item}>
						<div style={{display: 'flex', flex: 1, height: 420}}>
							<img
								width={'100%'}
								height={'100%'}
								src={"https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/613a3980eb558654bceee83b_navigation-slider(home).jpeg"}/>
						</div>
					</SwiperItem>
				))}
			</Swiper>
		</Wrapper>
	)
}

export default Carousel

const Wrapper = styled.div`
  display: flex;
  align-self: center;
  max-width: 97vmax;
  margin-top: 50px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`
const SwiperItem = styled(SwiperSlide)`
  display: flex;
  border-radius: 20px;
  overflow: hidden;
`
