import React from "react"
import styled from "styled-components"
import SectionTitle from "./SectionTitle"
import SectionItem from "./SectionItem"
import {faCode} from "@fortawesome/pro-solid-svg-icons/faCode"

const Integration = () => {
	return (
		<Wrapper id="integration">
			<SectionTitle title="Интеграция" icon={{backgroundColor: '#E75957', component: faCode, size: 25}}/>
			<SectionItem
				title="Импорт из Excel файла"
				price={0}
				priceDescription={'Бесплатно'}
				description="Скачайте образец Excel файла, чтобы самостоятельно импортировать данные."
			/>

			<SectionItem
				title="Индивидуальный формат Excel файла"
				price={5000}
				priceFrom
				priceDescription={'Единоразово'}
				description="Скачайте образец Excel файла, чтобы самостоятельно импортировать данные."
			/>

			<SectionItem
				title="Интеграция по API"
				price={0}
				priceDescription={'Бесплатно'}
				description="Обратитесь к нашей документации, чтобы интегрироваться самостоятельно."
			/>

			<SectionItem
				title="Индивидуальная интеграция с ERP"
				price={50000}
				priceFrom
				priceDescription={'Единоразово'}
				description="Вы можете заказть индивидуальную интеграцию, если методы из нашей библиотеки вам не подходят."
			/>
		</Wrapper>
	)
}

export default Integration

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 110px;
`
