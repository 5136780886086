import React, {useState} from "react"
import 'rc-slider/assets/index.css'
import Monitoring from "./Monitoring"
import MobileApp from "./MobileApp"
import Routing from "./Routing"
import Integration from "./Integration"
import Services from "./Services"
import Summary from "./Summary"


const Calculator = () => {
	const [monthlyRoutes, setMonthlyRoutes] = useState({count: 500, price: 20000})
	const [offices, setOffices] = useState({count: 1, price: 0})
	const [fines, setFines] = useState({count: 0, price: 0})
	const [sensors, setSensors] = useState({count: 0, price: 0})

	const [routeChecklist, setRouteChecklist] = useState(false)
	const [pointChecklist, setPointChecklist] = useState(false)
	const [barCodeScanner, setBarCodeScanner] = useState(false)

	const [monthlyPoints, setMonthlyPoints] = useState({count: 0, price: 0})

	const TOTAL =
		monthlyRoutes.price +
		offices.price +
		fines.price +
		sensors.price +
		(routeChecklist ? monthlyRoutes.count * 2.5 : 0) +
		(pointChecklist ? monthlyRoutes.count * 2.5 : 0) +
		(barCodeScanner ? monthlyRoutes.count * 1.2 : 0) +
		monthlyPoints.price


	const changeMonthlyRoutes = (count) => {
		if (count === null) count = 500
		let price = 0
		for (let i = 1; i < count + 1; i++) {
			if (i >= 1 && i <= 500) price = price + 40
			if (i >= 501 && i <= 900) price = price + 30
			if (i >= 901 && i <= 1800) price = price + 25
			if (i >= 1801 && i <= 3000) price = price + 21
			if (i >= 3001 && i <= 6000) price = price + 18
			if (i >= 6001 && i <= 9000) price = price + 15
			if (i >= 9001 && i <= 12000) price = price + 11
			if (i >= 12001 && i <= 18000) price = price + 8
		}

		setMonthlyRoutes({count, price})
	}

	const changeOffices = (count) => {
		if (count === null) count = 1
		setOffices({count: count, price: count > 5 ? (count - 5) * 500 : 0})
	}

	const changeSensors = (count) => {
		if (count === null) count = 0
		let price = 0
		for (let i = 1; i < count + 1; i++) {
			if (i >= 1 && i <= 15) price = price + 1200
			if (i >= 16 && i <= 30) price = price + 1000
			if (i >= 31 && i <= 60) price = price + 840
			if (i >= 61 && i <= 100) price = price + 705
			if (i >= 101 && i <= 200) price = price + 590
			if (i >= 201 && i <= 300) price = price + 495
			if (i >= 301 && i <= 400) price = price + 415
			if (i >= 401 && i <= 600) price = price + 348
		}
		setSensors({count, price})
	}

	const changeFines = (count) => {
		if (count === null) count = 0
		let price = 0
		if (count && count <= 15) price = count * 200
		if (count > 15 && count <= 30) price = 4000
		if (count > 30 && count <= 100) price = 6000
		if (count > 100 && count <= 200) price = 9000
		if (count > 200 && count <= 300) price = 12000
		if (count > 300 && count <= 400) price = 14000
		if (count > 400 && count <= 600) price = 18000
		setFines({count, price})
	}

	const changeMonthlyPoints = (count) => {
		let price = 0

		for (let i = 1; i < count + 1; i++) {
			if (i >= 1 && i <= 10000) price = 10000 * 1.25
			if (i >= 10001 && i <= 18000) price = price + 0.99
			if (i >= 18001 && i <= 36000) price = price + 0.83
			if (i >= 36001 && i <= 60000) price = price + 0.69
			if (i >= 60001 && i <= 120000) price = price + 0.59
			if (i >= 120001 && i <= 180000) price = price + 0.49
			if (i >= 180001 && i <= 240000) price = price + 0.41
			if (i >= 240001 && i <= 360000) price = price + 0.34
		}

		price = Math.round(price)
		setMonthlyPoints({count: count, price: price})
	}

	return (
		<>
			<Monitoring monthlyRoutes={monthlyRoutes} changeMonthlyRoutes={changeMonthlyRoutes}
						offices={offices} changeOffices={changeOffices} sensors={sensors} changeSensors={changeSensors}
			/>

			<MobileApp monthlyRoutes={monthlyRoutes} routeChecklist={routeChecklist}
					   setRouteChecklist={setRouteChecklist} pointChecklist={pointChecklist}
					   setPointChecklist={setPointChecklist} barCodeScanner={barCodeScanner}
					   setBarCodeScanner={setBarCodeScanner}
			/>

			<Routing monthlyPoints={monthlyPoints} changeMonthlyPoints={changeMonthlyPoints}/>

			<Services fines={fines} changeFines={changeFines}/>

			<Integration/>

			<Summary total={TOTAL}/>

			{/*<NeedHelp/>*/}
		</>
	)
}

export default Calculator
