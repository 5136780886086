import styled from "styled-components"

const Description = ({top = 0, children}) => {

	return (
		<Wrapper top={top}>
			{children}
		</Wrapper>
	)
}

export default Description

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  margin-top: ${props => props.top}px;
  max-width: 700px;
  font-size: 20px;
  font-weight: 300;
  color: rgb(152, 162, 177);
  letter-spacing: -.01em;
  line-height: 32px;
`
