import React from "react"
import styled from "styled-components"
import SectionTitle from "./SectionTitle"
import SectionItem from "./SectionItem"
import {faMobileAlt} from "@fortawesome/pro-solid-svg-icons/faMobileAlt"

const Monitoring = ({
						monthlyRoutes,
						routeChecklist,
						setRouteChecklist,
						pointChecklist,
						setPointChecklist,
						barCodeScanner,
						setBarCodeScanner
					}) => {

	const priceRouteChecklist = {
		head: ['Количество', 'Стоимость за МЛ'],
		data: [
			{range: 'Любое', price: 2.5}
		]
	}

	const pricePointChecklist = {
		head: ['Количество', 'Стоимость за МЛ'],
		data: [
			{range: 'Любое', price: 2.5}
		]
	}

	const priceBarcodeScanner = {
		head: ['Количество', 'Стоимость за МЛ'],
		data: [
			{range: 'Любое', price: 1.2}
		]
	}

	return (
		<Wrapper id="mobile-app">
			<SectionTitle title="Мобильное приложение" icon={{backgroundColor: '#FF9500', component: faMobileAlt}}/>

			<SectionItem
				title="Приём GPS данных устройства"
				price={0}
				priceDescription={'Бесплатно'}
				description="Опция подключается автоматически, если водитель разрешил отправку GPS координат."
			/>

			<SectionItem
				title="Календарь поездок"
				price={0}
				priceDescription={'Бесплатно'}
				description="Опция активируется автоматически."
			/>

			<SectionItem
				title="Предрейсовый чек-лист"
				switcher={{
					defaultChecked: false,
					onChange: setRouteChecklist
				}}
				info={monthlyRoutes.count}
				price={routeChecklist ? monthlyRoutes.count * 2.5 : 0}
				priceDetail={priceRouteChecklist}
				priceDescription={'Количество МЛ в месяц'}
				description="Включите эту опцию, чтобы водитель проходил обязательный чек-лист перед рейсом."
			/>

			<SectionItem
				title="Чек-лист на адресах"
				switcher={{
					defaultChecked: false,
					onChange: setPointChecklist
				}}
				info={monthlyRoutes.count}
				price={pointChecklist ? monthlyRoutes.count * 2.5 : 0}
				priceDetail={pricePointChecklist}
				priceDescription={'Количество МЛ в месяц'}
				description="Включите эту опцию, чтобы водитель проходил обязательный чек-лист на точках."
			/>

			<SectionItem
				title="Сканер штрих-кода"
				switcher={{
					defaultChecked: false,
					onChange: setBarCodeScanner
				}}
				info={monthlyRoutes.count}
				price={barCodeScanner ? monthlyRoutes.count * 1.2 : 0}
				priceDetail={priceBarcodeScanner}
				priceDescription={'Количество МЛ в месяц'}
				description="Включите эту опцию, чтобы водитель мог сканировать штрих-коды на коробках, для контроля правильность выданного груза."
			/>
		</Wrapper>
	)
}

export default Monitoring

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 110px;
`
