import styled from "styled-components"
import React from "react"
import Icon from "../../components/Icon/Icon"
import {faAt, faLeaf} from "@fortawesome/pro-regular-svg-icons"
import {HashLink} from 'react-router-hash-link'

const Footer = () => {

	return (
		<Wrapper>
			<Navigation>
				<NavigationColumn>
					<ColumnTitle>Приложение</ColumnTitle>
					<HashLink to="/mobile-app#route">Маршрутный лист</HashLink>
					<HashLink to="/mobile-app#points-info">Информация о точках</HashLink>
					{/*<HashLink to="/mobile-app#routing-optimization">Оптимизация объезда</HashLink>*/}
					<HashLink to="/mobile-app#invoices">Товарная накладная</HashLink>
					<HashLink to="/mobile-app#check-lists">Чек-листы</HashLink>
					<HashLink to="/mobile-app#photo-fixation">Фото-фиксация</HashLink>
					<HashLink to="/mobile-app#barcode-scanner">Сканер штрих-кодов</HashLink>
					<HashLink to="/mobile-app#government-fines">Штрафы ГИБДД</HashLink>
					<HashLink to="/mobile-app#job-calendar">Календарь поездок</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<ColumnTitle>Мониторинг</ColumnTitle>
					<HashLink to="/monitoring#terminal">Терминал</HashLink>
					<HashLink to="/monitoring#logs">Журнал событий</HashLink>
					<HashLink to="/monitoring#divisions">Поддержка филиалов</HashLink>
					<HashLink to="/monitoring#archive">Архив маршрутных листов</HashLink>
					<HashLink to="/monitoring#gps-sensor">GPS мониторинг</HashLink>
					<HashLink to="/monitoring#fuel-sensor">Контроль расхода топлива</HashLink>
					<HashLink to="/monitoring#temp-sensor">Температурный режим</HashLink>
					<HashLink to="/monitoring#door-sensor">Датчик открытия ворот</HashLink>
					<HashLink to="/monitoring#can-bus">CAN шина</HashLink>
					<HashLink to="/monitoring#eco-drive"><Icon name={faLeaf} size={14} color="#7cb305" right={2}/> ECO
						Drive</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<ColumnTitle>Маршрутизация</ColumnTitle>
					<HashLink to="/routing#map">Карта</HashLink>
					<HashLink to="/routing#metrics">Просчёт маршрутов</HashLink>
					<HashLink to="/routing#geocode">Определение координат</HashLink>
					<HashLink to="/routing#schedule">График отгрузки ТС</HashLink>
					<HashLink to="/routing#performance">Быстродействие</HashLink>
					<HashLink to="/routing#group-use">Групповое использование</HashLink>
					{/*<a href="/routing#lorry">Магистральные перевозки</a>*/}
				</NavigationColumn>
				{/*<NavigationColumn>
					<ColumnTitle>Сервисы</ColumnTitle>
					<a href="/services#analytics">Аналитика</a>
					<a href="/services#fines">Штрафы ГИБДД</a>
					<a href="/services#motivation">Система мотивции</a>
					<a href="/services#customer-links">Ссылка для клиента</a>
				</NavigationColumn>*/}

				<NavigationColumn>
					<ColumnTitle>Тарифы</ColumnTitle>
					<HashLink to="/price#monitoring">Мониторинг</HashLink>
					<HashLink to="/price#mobile-app">Мобильное приложение</HashLink>
					<HashLink to="/price#routing">Маршрутизация</HashLink>
					<HashLink to="/price#services">Сервисы</HashLink>
					<HashLink to="/price#integration">Интеграция</HashLink>

					<ColumnTitle style={{marginTop: 15}}>Дополнительно</ColumnTitle>
					<a href="/docs/privacy">Политика конфиденциальности</a>
					{/*<a href="/api">Руководство API</a>*/}
				</NavigationColumn>
			</Navigation>

			<ContactWrapper>
				<ContactContent>
					<Copyright>
						<div>АТС24 — Автоматизация Транспортных Систем</div>
						<div>115419 г. Москва, 2-й Рощинский проезд, дом 8</div>
						<div><a href="mailto:inbox@ats24.ru" target="_blank">inbox@ats24.ru</a></div>
					</Copyright>
					<Contacts>
						+7 (495) 212-91-55
						<Messengers>
							<a href="https://t.me/ats24ru" target="_blank">
								<img src="/assets/img/svg/telegram.svg" width={20} height={20}/>
							</a>
							<a href="https://wa.me/+79263794237" target="_blank">
								<img src="/assets/img/svg/whatsapp.svg" width={20} height={20}/>
							</a>
							<a href="mailto:inbox@ats24.ru" target="_blank">
								<Icon name={faAt} color="#909294" size={20}/>
							</a>
							{/*<a href="https://discord.gg/t3wEps5y" target="_blank">
								<img src="/assets/img/svg/discord.svg" width={20} height={20}/>
							</a>*/}
						</Messengers>
					</Contacts>
				</ContactContent>
			</ContactWrapper>
		</Wrapper>
	)
}

export default Footer

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const Navigation = styled.div`
    display: flex;
    width: 100%;
    max-width: 1280px;
    padding-inline: 20px;
    margin-top: 110px;
    align-self: center;
`

const NavigationColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    a {
        color: #FFFFFF85;
        padding-block: 10px;
        font-size: 13px;
        font-weight: 300;
    }

    a:hover {
        color: ${props => props.theme.colorLinkHover};
        transition: .3s ease;
    }
`
const ColumnTitle = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1.1px;
    margin-bottom: 15px;
`
const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
    background-color: ${props => props.theme.colorBgCard};
    color: #FFFFFF85;

    a {
        color: #FFFFFF85;
        text-decoration: underline;
    }
`
const ContactContent = styled.div`
    display: flex;
    width: 100%;
    max-width: 1280px;
    align-self: center;
    padding: 30px 20px;
    font-weight: 300;
`
const Copyright = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
`
const Contacts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
`
const Messengers = styled.div`
    display: flex;
    gap: 10px;
`

