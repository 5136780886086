import React from "react"
import styled from "styled-components"

const partners = [
	{image: require("../../../assets/img/partner/dpd.png")},
	{image: require("../../../assets/img/partner/magnit.png")},
	{image: require("../../../assets/img/partner/obi.png")},
	{image: require("../../../assets/img/partner/rzp.png")},
	{image: require("../../../assets/img/partner/sdg.png")},
	{image: require("../../../assets/img/partner/grain.png")}
]

const PartnerScreen = () => {

	return (
		<Wrapper>
			Нам доверяют
			<Partner>
				{
					partners.map((item, index) => (
						<PartnerItem key={"prt-" + index} image={item.image} href={item.url}/>
					))
				}
			</Partner>
		</Wrapper>
	)
}

export default PartnerScreen

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  font-size: 12px;
  letter-spacing: .1em;
  text-transform: uppercase;
`
const Partner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`
const PartnerItem = styled.div`
  display: flex;
  width: 150px;
  margin: 10px 15px;
  height: 80px;
  background: url(${props => props.image}) no-repeat center center;
  background-size: 100%;
`
