import styled from "styled-components"
import {Anchor, Col, Divider, Row, Typography} from 'antd'
import ContentBlock from "../../components/UI/ContentBlock"
import {useEffect} from "react"

const {Title, Paragraph} = Typography

const PrivacyScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Политика конфиденциальности'
	}, [])

	return (
		<ContentBlock top={40}>
			<Row gutter={50}>
				<Col span={16}>
					<Typography>
						<Title id="privacy-policy">Политика конфиденциальности</Title>

						<Paragraph>
							Эта политика конфиденциальности применяется к приложению АТС24 (далее именуемому
							"Приложение")
							для мобильных устройств, созданному АТС24 (далее именуемому "Поставщик услуг") в качестве
							бесплатного сервиса. Этот сервис предназначен для использования как есть.
						</Paragraph>

						<Title level={2} id="information-collection-and-use">Сбор и использование информации</Title>

						<Paragraph>
							При использовании и загрузке Приложения собирается информация. Эта информация может
							включать:
						</Paragraph>
						<Paragraph>
							<ul>
								<li>
									IP-адрес вашего устройства
								</li>
								<li>
									Посещенные вами страницы Приложения, дата и время посещения, время, проведенное на
									этих
									страницах
								</li>
								<li>
									Время, проведенное в приложении
								</li>
								<li>
									Операционная система вашего мобильного устройства
								</li>
							</ul>
						</Paragraph>
						<Paragraph>
							Приложение также собирает информацию о местоположении вашего устройства, которая помогает
							Поставщику услуг определить ваше приблизительное географическое положение и использовать эту
							информацию в следующих целях:
						</Paragraph>
						<Paragraph>
							<ul>
								<li>
									Геолокационные службы: Поставщик услуг использует данные о местоположении для
									предоставления функций, таких как персонализированный контент, релевантные
									рекомендации
									и услуги на основе местоположения.
								</li>
								<li>
									Аналитика и улучшения: Агрегированные и анонимные данные о местоположении помогают
									Поставщику услуг анализировать поведение пользователей, выявлять тенденции и
									улучшить
									общую производительность и функциональность Приложения.
								</li>
								<li>
									Сторонние сервисы: Периодически Поставщик услуг может передавать анонимные данные о
									местоположении внешним сервисам. Эти сервисы помогают улучшить Приложение и
									оптимизировать предложения.
								</li>
							</ul>
						</Paragraph>
						<Paragraph>
							Поставщик услуг может использовать предоставленную вами информацию, чтобы время от времени
							связываться с вами и предоставлять важную информацию, необходимые уведомления и
							маркетинговые
							акции.
						</Paragraph>
						<Paragraph>
							Для улучшения опыта использования Приложения Поставщик услуг может потребовать
							предоставления
							некоторой лично идентифицируемой информации, включая, но не ограничиваясь, inbox@ats24.ru.
							Информация, которую запрашивает Поставщик услуг, будет сохранена ими и использована в
							соответствии с настоящей политикой конфиденциальности.
						</Paragraph>

						<Title level={2} id="third-party-access">Доступ третьих лиц</Title>
						<Paragraph>
							Только агрегированные и анонимизированные данные периодически передаются внешним сервисам
							для
							улучшения Приложения и предоставления услуг Поставщика услуг. Поставщик услуг может
							передавать
							вашу информацию третьим лицам в соответствии с описанными в настоящем заявлении о
							конфиденциальности способами.
						</Paragraph>
						<Paragraph>
							Пожалуйста, обратите внимание, что Приложение использует сторонние сервисы, которые имеют
							собственную политику конфиденциальности в отношении обработки данных. Ниже приведены ссылки
							на
							политику конфиденциальности сторонних сервис-провайдеров:
						</Paragraph>
						<Paragraph>
							<ul>
								<li>
									<a href="https://www.google.com/policies/privacy/" target="_blank"
									   rel="noopener noreferrer">Google Play Services</a></li>
								<li>
									<a href="https://sentry.io/privacy/" target="_blank"
									   rel="noopener noreferrer">Sentry</a>
								</li>
							</ul>
						</Paragraph>
						<Paragraph>
							Поставщик услуг может раскрывать предоставленную пользователем информацию и автоматически
							собранную информацию в следующих случаях:
						</Paragraph>
						<Paragraph>
							<ul>
								<li>по требованию закона, такого как соблюдение судебного повестки или подобного
									юридического процесса;
								</li>
								<li>если они искренне верят, что раскрытие необходимо для защиты своих прав, защиты
									вашей
									безопасности или безопасности других, расследования мошенничества или ответа на
									запрос
									правительства;
								</li>
								<li>с доверенными поставщиками услуг, которые работают от их имени, не имеют
									независимого
									использования информации, которую мы раскрываем им, и согласились соблюдать правила,
									изложенные в настоящем заявлении о конфиденциальности.
								</li>
							</ul>
						</Paragraph>

						<Title level={2} id="opt-out-rights">Право отказа</Title>
						<Paragraph>
							Вы можете прекратить сбор информации приложением, легко удалив его. Вы можете использовать
							стандартные процессы удаления, которые доступны в вашем мобильном устройстве или через
							магазин
							приложений или сеть мобильных приложений.
						</Paragraph>

						<Title level={2} id="data-retention-policy">Политика хранения данных</Title>
						<Paragraph>
							Поставщик услуг будет хранить предоставленные пользователем данные на протяжении всего
							использования приложения и разумное время после этого. Если вы хотите, чтобы они удалили
							предоставленные вами данные, пожалуйста, свяжитесь с ними по адресу inbox@ats24.ru, и они
							ответят в разумные сроки.
						</Paragraph>

						<Title level={2} id="children">Дети</Title>
						<Paragraph>
							Поставщик услуг не использует приложение с целью сознательного сбора данных или маркетинга к
							детям в возрасте до 13 лет.
						</Paragraph>
						<Paragraph>
							Приложение не предназначено для лиц младше 13 лет. Поставщик услуг не собирает осознанно
							лично
							идентифицируемую информацию от детей младше 13 лет. В случае обнаружения того, что ребенок
							младше 13 лет предоставил персональную информацию, Поставщик услуг немедленно удалит ее с
							серверов. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил нам
							личную информацию, пожалуйста, свяжитесь с Поставщиком услуг (inbox@ats24.ru), чтобы они
							могли
							принять необходимые меры.
						</Paragraph>

						<Title level={2} id="security">Безопасность</Title>
						<Paragraph>
							Поставщик услуг обеспокоен сохранением конфиденциальности вашей информации. Поставщик услуг
							предоставляет физические, электронные и процедурные меры защиты для защиты информации,
							которую
							он обрабатывает и хранит.
						</Paragraph>

						<Title level={2} id="changes">Изменения</Title>
						<Paragraph>
							Настоящая политика конфиденциальности может быть периодически обновлена по любой причине.
							Поставщик услуг уведомит вас о любых изменениях в политике конфиденциальности, обновив эту
							страницу новой политикой конфиденциальности. Рекомендуется регулярно проверять эту политику
							конфиденциальности на наличие изменений, поскольку продолжение использования означает
							согласие с
							этими изменениями.
						</Paragraph>
						<Paragraph>
							Эта политика конфиденциальности действует с 26 апреля 2024 года.
						</Paragraph>

						<Title level={2} id="your-consent">Ваше согласие</Title>
						<Paragraph>
							Используя Приложение, вы соглашаетесь на обработку вашей информации в соответствии с
							настоящей
							политикой конфиденциальности в настоящее время и в последующих изменениях.
						</Paragraph>

						<Title level={2} id="contact-us">Свяжитесь с нами</Title>
						<Paragraph>
							Если у вас возникли вопросы о конфиденциальности при использовании Приложения или если у вас
							есть вопросы о практике, пожалуйста, свяжитесь с Поставщиком услуг по электронной почте
							inbox@ats24.ru.
						</Paragraph>
					</Typography>

					<Divider style={{marginBlock: 70}}/>

					<Typography>
						<Title id="privacy-policy-en">Privacy Policy</Title>

						<Paragraph>
							This privacy policy applies to the АТС24 app (hereby referred to as "Application") for
							mobile
							devices that was created by АТС24 (hereby referred to as "Service Provider") as a Free
							service.
							This service is intended for use "AS IS".
						</Paragraph>

						<Title level={2} id="information-collection-and-use-en">Information Collection and Use</Title>

						<Paragraph>
							The Application collects information when you download and use it. This information may
							include
							information such as
						</Paragraph>
						<Paragraph>
							<ul>
								<li>
									Your device's Internet Protocol address (e.g. IP address)
								</li>
								<li>
									The pages of the Application that you visit, the time and date of your visit, the
									time
									spent on those pages
								</li>
								<li>
									The time spent on the Application
								</li>
								<li>
									The operating system you use on your mobile device
								</li>
							</ul>
						</Paragraph>
						<Paragraph>
							The Application collects your device's location, which helps the Service Provider determine
							your
							approximate geographical location and make use of in below ways:
						</Paragraph>
						<Paragraph>
							<ul>
								<li>
									Geolocation Services: The Service Provider utilizes location data to provide
									features
									such as personalized content, relevant recommendations, and location-based services.
								</li>
								<li>
									Analytics and Improvements: Aggregated and anonymized location data helps the
									Service
									Provider to analyze user behavior, identify trends, and improve the overall
									performance
									and functionality of the Application.
								</li>
								<li>
									Third-Party Services: Periodically, the Service Provider may transmit anonymized
									location data to external services. These services assist them in enhancing the
									Application and optimizing their offerings.
								</li>
							</ul>
						</Paragraph>
						<Paragraph>
							The Service Provider may use the information you provided to contact you from time to time
							to
							provide you with important information, required notices and marketing promotions.
						</Paragraph>
						<Paragraph>
							For a better experience, while using the Application, the Service Provider may require you
							to
							provide us with certain personally identifiable information, including but not limited to
							inbox@ats24.ru. The information that the Service Provider request will be retained by them
							and
							used as described in this privacy policy.
						</Paragraph>

						<Title level={2} id="third-party-access-en">Third Party Access</Title>
						<Paragraph>
							Only aggregated, anonymized data is periodically transmitted to external services to aid the
							Service Provider in improving the Application and their service. The Service Provider may
							share
							your information with third parties in the ways that are described in this privacy
							statement.
						</Paragraph>
						<Paragraph>
							Please note that the Application utilizes third-party services that have their own Privacy
							Policy about handling data. Below are the links to the Privacy Policy of the third-party
							service
							providers used by the Application:
						</Paragraph>
						<Paragraph>
							<ul>
								<li>
									<a href="https://www.google.com/policies/privacy/" target="_blank"
									   rel="noopener noreferrer">Google Play Services</a></li>
								<li>
									<a href="https://sentry.io/privacy/" target="_blank"
									   rel="noopener noreferrer">Sentry</a>
								</li>
							</ul>
						</Paragraph>

						<Paragraph>
							The Service Provider may disclose User Provided and Automatically Collected Information:
						</Paragraph>
						<Paragraph>
							<ul>
								<li>
									as required by law, such as to comply with a subpoena, or similar legal process;
								</li>
								<li>
									when they believe in good faith that disclosure is necessary to protect their
									rights,
									protect your safety or the safety of others, investigate fraud, or respond to a
									government request;
								</li>
								<li>
									with their trusted services providers who work on their behalf, do not have an
									independent use of the information we disclose to them, and have agreed to adhere to
									the
									rules set forth in this privacy statement.
								</li>
							</ul>
						</Paragraph>

						<Title level={2} id="opt-out-rights-en">Opt-Out Rights</Title>
						<Paragraph>
							You can stop all collection of information by the Application easily by uninstalling it. You
							may
							use the standard uninstall processes as may be available as part of your mobile device or
							via
							the mobile application marketplace or network.
						</Paragraph>

						<Title level={2} id="data-retention-policy-en">Data Retention Policy</Title>
						<Paragraph>
							The Service Provider will retain User Provided data for as long as you use the Application
							and
							for a reasonable time thereafter. If you'd like them to delete User Provided Data that you
							have
							provided via the Application, please contact them at inbox@ats24.ru and they will respond in
							a
							reasonable time.
						</Paragraph>

						<Title level={2} id="children-en">Children</Title>
						<Paragraph>
							The Service Provider does not use the Application to knowingly solicit data from or market
							to
							children under the age of 13.
						</Paragraph>
						<Paragraph>
							Приложение не предназначено для лиц младше 13 лет. Поставщик услуг не собирает осознанно
							лично
							идентифицируемую информацию от детей младше 13 лет. В случае обнаружения того, что ребенок
							младше 13 лет предоставил персональную информацию, Поставщик услуг немедленно удалит ее с
							серверов. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил нам
							личную информацию, пожалуйста, свяжитесь с Поставщиком услуг (inbox@ats24.ru), чтобы они
							могли
							принять необходимые меры.
						</Paragraph>

						<Title level={2} id="security-en">Security</Title>
						<Paragraph>
							The Service Provider is concerned about safeguarding the confidentiality of your
							information.
							The Service Provider provides physical, electronic, and procedural safeguards to protect
							information the Service Provider processes and maintains.
						</Paragraph>

						<Title level={2} id="changes-en">Changes</Title>
						<Paragraph>
							This Privacy Policy may be updated from time to time for any reason. The Service Provider
							will
							notify you of any changes to the Privacy Policy by updating this page with the new Privacy
							Policy. You are advised to consult this Privacy Policy regularly for any changes, as
							continued
							use is deemed approval of all changes.
						</Paragraph>
						<Paragraph>
							This privacy policy is effective as of 2024-04-26
						</Paragraph>

						<Title level={2} id="your-consent-en">Your Consent</Title>
						<Paragraph>
							By using the Application, you are consenting to the processing of your information as set
							forth
							in this Privacy Policy now and as amended by us.
						</Paragraph>

						<Title level={2} id="contact-us-en">Contact Us</Title>
						<Paragraph>
							If you have any questions regarding privacy while using the Application, or have questions
							about
							the practices, please contact the Service Provider via email at inbox@ats24.ru.
						</Paragraph>
					</Typography>
				</Col>
				<Col span={8}>
					<Anchor
						style={{backdropFilter: 'blur(10px)'}}
						affix
						offsetTop={120}
						targetOffset={100}
						items={[
							{
								key: "privacy-policy",
								href: '#privacy-policy',
								title: 'Политика конфиденциальности',
								children: [
									{
										key: "information-collection-and-use",
										href: '#information-collection-and-use',
										title: 'Сбор и использование информации',
									},
									{
										key: "third-party-access",
										href: '#third-party-access',
										title: 'Доступ третьих лиц',
									},
									{
										key: "opt-out-rights",
										href: '#opt-out-rights',
										title: 'Право отказа',
									},
									{
										key: "data-retention-policy",
										href: '#data-retention-policy',
										title: 'Политика хранения данных',
									},
									{
										key: "children",
										href: '#children',
										title: 'Дети',
									},
									{
										key: "security",
										href: '#security',
										title: 'Безопасность',
									},
									{
										key: "changes",
										href: '#changes',
										title: 'Изменения',
									},
									{
										key: "your-consent",
										href: '#your-consent',
										title: 'Ваше согласие',
									},
									{
										key: "contact-us",
										href: '#contact-us',
										title: 'Свяжитесь с нами',
									},
								],
							},

							{
								key: "privacy-policy-en",
								href: '#privacy-policy-en',
								title: 'Privacy Policy',
								children: [
									{
										key: "information-collection-and-use-en",
										href: '#information-collection-and-use-en',
										title: 'Information Collection and Use',
									},
									{
										key: "third-party-access-en",
										href: '#third-party-access-en',
										title: 'Third Party Access',
									},
									{
										key: "opt-out-rights-en",
										href: '#opt-out-rights-en',
										title: 'Opt-Out Rights',
									},
									{
										key: "data-retention-policy-en",
										href: '#data-retention-policy-en',
										title: 'Data Retention Policy',
									},
									{
										key: "children-en",
										href: '#children-en',
										title: 'Children',
									},
									{
										key: "security-en",
										href: '#security-en',
										title: 'Security',
									},
									{
										key: "changes-en",
										href: '#changes-en',
										title: 'Changes',
									},
									{
										key: "your-consent-en",
										href: '#your-consent-en',
										title: 'Your Consent',
									},
									{
										key: "contact-us-en",
										href: '#contact-us-en',
										title: 'Contact Us',
									},
								],
							},
						]}
					/>
				</Col>
			</Row>
		</ContentBlock>
	)
}

export default PrivacyScreen

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1880px;
`
