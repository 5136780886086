import React from "react"
import styled from "styled-components"
import SectionTitle from "./SectionTitle"
import SectionItem from "./SectionItem"
import {faRoute} from "@fortawesome/pro-solid-svg-icons/faRoute"

const Routing = ({monthlyPoints, changeMonthlyPoints}) => {

	const priceMonthlyPoints = {
		head: ['Номер по счету', 'Стоимость, ед.'],
		data: [
			{range: '< 10k', price: '1.25'},
			{range: '10k − 18k', price: 0.99},
			{range: '18k − 36k', price: 0.83},
			{range: '36k − 60k', price: 0.69},
			{range: '60k − 120k', price: 0.59},
			{range: '120k − 180k', price: 0.49},
			{range: '180k − 240k', price: 0.41},
			{range: '260k − 360k', price: 0.34},
			{range: '360k +', price: 'обсуждается'}
		]
	}

	const priceCoordDetect = {
		head: ['Количество', 'Стоимость за адрес'],
		data: [
			{range: '∞', price: 0.1}
		]
	}
	return (
		<Wrapper id="routing">
			<SectionTitle title="Маршрутизация" sup="опционально"
						  icon={{backgroundColor: '#88db57', component: faRoute, size: 25}}/>
			<SectionItem
				title="Количество точек в месяц"
				slider={{
					min: 0,
					max: 340000,
					marks: {12000: '10k', 100000: '100k', 200000: '200k', 300000: '300k'},
					step: 5000,
					onChange: changeMonthlyPoints,
					value: monthlyPoints.count
				}}
				info={monthlyPoints.count}
				price={monthlyPoints.price}
				priceDetail={priceMonthlyPoints}
				priceDescription={'Количество точек в месяц'}
				description="Это базовый показатель для определения ежемесячной стоимости."
			/>

			<SectionItem
				title="Автоматическая маршрутизация"
				price={0}
				priceDescription={'Бесплатно'}
				description="Опция активируется автоматически."
			/>
		</Wrapper>
	)
}

export default Routing

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 110px;
`
