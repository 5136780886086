import styled from "styled-components"
import ContentBlock from "../../components/UI/ContentBlock"
import {Button, Col, Form, Input, message, Radio, Row, Space} from "antd"
import {useEffect, useState} from "react"

const ContactScreen = () => {

	const [contact, setContact] = useState(null)
	const [area, setArea] = useState(null)
	const [amount, setAmount] = useState(null)
	const [description, setDescription] = useState('')

	useEffect(() => {
		document.title = 'АТС24 — Контакты'
	}, [])

	const sendForm = async () => {
		await fetch('https://api.ats24.ru/v2/feedback', {
			method: "POST",
			body: JSON.stringify({contact, area, amount, description}), // данные могут быть 'строкой' или {объектом}!
			headers: {
				"Content-Type": "application/json"
			}
		})

		setContact('')
		setArea(null)
		setAmount(null)
		setDescription(null)

		message.open({
			type: 'success',
			content: 'Форма успешно отправлена. В скором времени мы свяжемся с вами.',
			duration: 4
		})
	}

	return (
		<ContentBlock top={120}>
			<ContactWrapper>
				<InfoWrapper>
					<h2>Связаться с нами</h2>
					<p>
						Для быстрой и удобной связи напишите нам в Telegram — <a
						href="https://t.me/ats24ru" target="_blank">@ats24ru</a>
					</p>
					<p>
						Или позвоните по номеру +7 (495) 212-91-55
					</p>
					<p>
						Заполните форму, чтобы мы связались предпочтительным для вас способом.
					</p>
				</InfoWrapper>
				<MailWrapper>
					<Form>
						<MailBlock>
							<Title>*Ваш email, телефон или аккаунт в Telegram</Title>

							<Input value={contact} onChange={e => setContact(e.target.value)}
								   placeholder="name@domain.ru / +7 xxx xxxxxxx / @telegram_login"/>
						</MailBlock>
						<MailBlock>
							<Row gutter={50}>
								<Col span={12}>
									<Title>Сфера деятельности</Title>
									<Radio.Group onChange={e => setArea(e.target.value)} value={area}>
										<Space direction="vertical" size={15}>
											<Radio value="food">Продукты питания</Radio>
											<Radio value="groupCargo">Сборные грузы</Radio>
											<Radio value="domestiServices">Бытовые услуги</Radio>
											<Radio value="freeze">Заморозка</Radio>
											<Radio value="lorry">Магистральные перевозки</Radio>
											<Radio value="another">другая</Radio>
										</Space>
									</Radio.Group>
								</Col>
								<Col span={12}>
									<Title>Количество адресов в сутки</Title>
									<Radio.Group onChange={e => setAmount(e.target.value)} value={amount}>
										<Space direction="vertical" size={15}>
											<Radio value="10-50">10
												<d>—</d>
												50</Radio>
											<Radio value="50-500">50
												<d>—</d>
												500</Radio>
											<Radio value="500-2000">500
												<d>—</d>
												2 000</Radio>
											<Radio value="2000-5000">2 000
												<d>—</d>
												5 000</Radio>
											<Radio value="5000-10000">5 000
												<d>—</d>
												10 000</Radio>
											<Radio value=">10000">более 10 000</Radio>
										</Space>
									</Radio.Group>
								</Col>
							</Row>

						</MailBlock>
						<MailBlock>
							<Title>Задачи, которые вы хотите решить</Title>
							<Input.TextArea onChange={e => setDescription(e.target.value)} rows={6} value={description}
											placeholder="Ваше сообщение..."/>
						</MailBlock>
						<Button type="primary" disabled={!contact?.length} onClick={() => sendForm()} shape="round"
								size="large"
								htmlType="submit">Отправить</Button>
					</Form>
				</MailWrapper>
			</ContactWrapper>
		</ContentBlock>
	)
}

export default ContactScreen

const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const InfoWrapper = styled.div`
  flex: 1;
  min-width: 400px;

  p {
    font-size: 20px;
    font-weight: 300;
    color: ${props => props.theme.colorTextDescription};
    line-height: 32px;
  }
`
const MailWrapper = styled.div`
  flex: 1;
  min-width: 500px;
  max-width: 700px;
  margin-left: 50px;
  padding: 50px;
  border-radius: 8px;
  background: ${props => props.theme.colorBgCard};
`
const MailBlock = styled.div`
  padding-bottom: 32px;

  d {
    padding-inline: 3px;
  }
`
const Title = styled.div`
  font-size: 12px;
  padding-bottom: 10px;
`
