import styled from "styled-components"
import {useMemo} from "react"
import ContentBlock from "./ContentBlock"

const ContentHeader = ({height = 500, media = null, title = null, description = null, children}) => {

	media = useMemo(() => {
		const result = {
			source: media,
			type: 'image'
		}
		if (media) {
			const extension = media.split('.')
			if (['webm', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov', 'qt', 'flv', 'swf', 'avchd'].indexOf(extension[extension.length - 1].toLowerCase()) >= 0) {
				result.type = 'video'
			}

			return result
		}

		return null
	}, [media])

	const ImageLayer = () => (
		<ImageWrapper source={media.source} height={height}>
			<ContentWrapper gradient>
				<ContentBlock>
					<h1>{title}</h1>
					<p>{description}</p>
				</ContentBlock>
			</ContentWrapper>
		</ImageWrapper>
	)

	const VideoLayer = () => (
		<VideoWrapper height={height}>
			<video className="fullscreen"
				   src="/assets/video/routing.mp4" playsInline autoPlay
				   muted loop>
			</video>
			<ContentWrapper gradient>
				<ContentBlock>
					<h1>{title}</h1>
					<p>{description}</p>
				</ContentBlock>
			</ContentWrapper>
		</VideoWrapper>
	)

	if (media.type === 'image') return <ImageLayer/>
	if (media.type === 'video') return <VideoLayer/>
	return null
}

export default ContentHeader

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1920px;
  min-height: ${props => props.height}px;

  h1 {
    text-shadow: 1px 1px 1px #000000;
  }

  p {
    max-width: 600px;
    font-size: 20px;
    text-shadow: 1px 1px 1px #000000;
  }

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${props => props.source});
`
const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1920px;
  min-height: ${props => props.height}px;

  h1 {
    text-shadow: 1px 1px 1px #000000;
  }

  p {
    max-width: 600px;
    font-size: 20px;
    text-shadow: 1px 1px 1px #000000;
  }

  video.fullscreen {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
const ContentWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => !props.gradient ? 'rgba(0, 0, 0, 65%)' : 'unset'};
  background-image: ${props => props.gradient ? 'linear-gradient(rgba(14, 16, 18, .4), rgba(14, 16, 18, .4)), linear-gradient(90deg, rgba(18, 22, 33, .8), rgba(18, 22, 33, .6) 40%, rgba(30, 35, 48, .4) 75%, transparent)' : 'unset'};
`
