import styled from "styled-components"
import {Anchor, Col, Row} from "antd"
import ContentBlock from "../../components/UI/ContentBlock"
import Icon from "../../components/Icon/Icon"
import {faBrowsers, faEgg, faMicrochipAi} from "@fortawesome/pro-light-svg-icons"
import Card from "../../components/UI/Card"
import ContentHeader from "../../components/UI/ContentHeader"
import ReadyToStart from "../../components/UI/ReadyToStart"
import {useEffect} from "react"

const RoutingScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Маршрутизация заказов, заказы на карте'
	}, [])

	return (
		<>
			<ContentHeader
				media="/assets/video/routing-compressed.mp4"
				orientation="left"
				title="Маршрутизация"
				description="Быстрый, мощный и удобный инстурмент для автоматической и ручной маршрутизации."
			/>

			<Menu>
				<Anchor className="horizontal-menu-anchor"
						direction="horizontal"
						targetOffset={140}
						items={[
							{key: 'map', href: '#map', title: 'Интерактивная карта'},
							{key: 'metrics', href: '#metrics', title: 'Просчёт маршрутов'},
							{key: 'geocode', href: '#geocode', title: 'Определение координат'},
							{key: 'schedule', href: '#schedule', title: 'График отгрузки'},
							{key: 'performance', href: '#performance', title: 'Быстродействие'},
							{key: 'group-use', href: '#group-use', title: 'Групповое использование'}
						]}
				/>
			</Menu>

			<ContentBlock top={110}>
				<Row gutter={24}>
					<Col span={8}>
						<Widget>
							<Icon name={faBrowsers} color="#057AFD" size={45}/>
							<WidgetTitle>
								Работа в браузере
							</WidgetTitle>
							<WidgetDescription>
								Маршрутизируйте в браузере из любого места. Больше никаких установок программ и вызова
								мастера.
							</WidgetDescription>
						</Widget>

					</Col>
					<Col span={8}>
						<Widget>
							<Icon name={faMicrochipAi} color="#057AFD" size={45}/>
							<WidgetTitle>
								Автоматически или руками
							</WidgetTitle>
							<WidgetDescription>
								Никогда не было так просто настроить и использовать автомат. Результат,
								при необходимости, легко поправить вручную.
							</WidgetDescription>
						</Widget>
					</Col>
					<Col span={8}>
						<Widget>
							<Icon name={faEgg} color="#057AFD" size={45}/>
							<WidgetTitle>
								Простой интерфейс
							</WidgetTitle>
							<WidgetDescription>
								Это самый простой в использовании инструмент маршрутизации на рынке. Мы гарантируем,
								что вы научитесь за 1 час.
							</WidgetDescription>
						</Widget>

					</Col>
				</Row>

				<Card id="map" title="Интерактивная карта" image="/assets/img/png/routing/map.png">
					<p>
						Выделите точки на карте и получите готовые маршрутные листы всего в два клика. Вы можете выбрать
						автоматический или ручной режим.
					</p>
					<p>
						Логист в любой момент может скорректировать маршрутный лист по своему усмотрению.
					</p>
				</Card>

				<Card id="metrics" title="Просчёт маршрутов" right image="/assets/img/png/routing/metrics.png">
					<p>
						Мы просчитываем каждый маршрут по реальным дорогам, поэтому у нас есть детальная
						информация о них:
						<ul>
							<li>Время выезда со склада</li>
							<li>Плановое время посещения каждой точки</li>
							<li>Расстояния всех участков маршрута</li>
							<li>Время возвращения на склад</li>
						</ul>
					</p>
				</Card>

				<Card id="geocode" top={70} title="Определение координат"
					  image="/assets/img/png/routing/geocode.png">
					<p>Мы автоматически определим координаты ваших адресов и покажем их водителям.</p>
					<p>Сложный подъезд к зданию? Не проблема, укажите точное место парковки, и мы запомним его.</p>
				</Card>

				<Card id="schedule" title="График отгрузки ТС" right image="/assets/img/png/routing/schedule.png">
					<p>
						После маршрутизации вы получите детальный график и порядок отгрузки транспорта со склада.
					</p>
					<p>
						Используйте эти данные, чтобы равномерно распределить нагрузку и избежать очередей в пиковые
						часы.
					</p>
				</Card>

				<Card id="performance" title="Быстродействие" image="/assets/img/png/routing/performance.png">
					<p>
						Все вычисления выполняются на наших серверах, что позволяет маршрутизировать из любой точки мира
						с одинаковым комфортом.
					</p>
					<p>Для работы вам подойдет любое современное устройство с браузером.</p>
				</Card>

				<Card id="group-use" title="Групповое использование" image="/assets/img/png/routing/group-use.png"
					  right>
					<p>Любое действие одного логиста моментально отображается в интерфейсе другого, что позволяет
						нескольким специалистам работать одновременно.</p>
				</Card>

				<ReadyToStart/>
			</ContentBlock>
		</>
	)
}

export default RoutingScreen
const Menu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 62px;
    width: 100%;
    background: #161A1D;
    height: 49px;
    z-index: 1;
`

const Widget = styled.div`
    padding: 32px;
    border-radius: 10px;
    background-color: ${props => props.theme.colorBgCard};
`
const WidgetTitle = styled.h5`
    margin-top: 20px;
`
const WidgetDescription = styled.div`
    color: #8b94a1;
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
`
