import React, {useState} from "react"
import styled from "styled-components"
import Slider from "rc-slider"
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown"
import {faChevronUp} from "@fortawesome/pro-solid-svg-icons/faChevronUp"
import Icon from "../../../components/Icon/Icon"
import {InputNumber, Switch} from "antd"

const SectionItem = ({
						 title,
						 titleSup = null,
						 description,
						 priceDetail = null,
						 priceDescription = '',
						 slider = null,
						 switcher = null,
						 info = '',
						 price,
						 priceFrom
					 }) => {

	const [showPriceDetail, setShowPriceDetail] = useState(false)

	const sliderProps = {
		trackStyle: {backgroundColor: '#3d9bff', height: 8},
		handleStyle: {borderColor: '#007AFC', height: 20, width: 20, marginTop: -6, backgroundColor: '#007AFC'},
		railStyle: {backgroundColor: '#47474B', height: 8}
	}

	const inputNumberProps = {
		style: {width: '80%', fontSize: 19, fontWeight: '500', padding: 0, margin: 0},
		formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	}

	const SwitchProps = {
		activetrackcolor: '#7abaff', activethumbcolor: '#007AFC', trackcolor: '#BDBDBD',
		thumbcolor: '#939393'
	}


	return (
		<Wrapper>
			<PriceBody>
				<TitleSide>
					<PriceTitle>
						{title}
						{titleSup &&
							<sup>{titleSup}</sup>
						}
					</PriceTitle>
					<PriceDescription>
						{description}
					</PriceDescription>
					{priceDetail &&
						<PriceDetailButton onClick={() => setShowPriceDetail(!showPriceDetail)}>
							Детализация <Icon name={showPriceDetail ? faChevronUp : faChevronDown}
											  style={{marginLeft: 7}}
											  size={8}
											  color={'#ccced1'}/>
						</PriceDetailButton>}
				</TitleSide>
				<TuningSide>
					{slider ? <Slider {...slider} {...sliderProps}/> : null}
					{switcher ? <Switch {...switcher} {...SwitchProps}/> : null}
				</TuningSide>
				<InfoSide>
					{slider ? <InputNumber
						{...inputNumberProps}
						{...slider}
					/> : info.toLocaleString('ru-RU')}
					<PriceDescription>
						{priceDescription}
					</PriceDescription>
				</InfoSide>
				<PriceSide>
					{priceFrom && 'от '}{price.toLocaleString('ru-RU')}<span>₽</span>
				</PriceSide>
			</PriceBody>
			{
				(priceDetail && showPriceDetail) && (
					<PriceDetailWrapper>
						<PriceDetail>
							<PriceDetailHead>
								<div>{priceDetail.head[0]}</div>
								<div>{priceDetail.head[1]}</div>
							</PriceDetailHead>
							{priceDetail.data.map((data, index) => (
								<PriceDetailBody key={'pr-' + index}>
									<div>{data.range}</div>
									<div>{data.price}</div>
								</PriceDetailBody>
							))}
						</PriceDetail>
					</PriceDetailWrapper>
				)
			}
		</Wrapper>
	)
}

export default SectionItem

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 1px solid #2e2e46;
`
const PriceBody = styled.div`
  display: flex;
  flex: 1;
`
const TitleSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: .35;
  line-height: 24px;
  padding: 32px 0 20px 0;
`
const PriceTitle = styled.div`
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;

  sup {
    margin-left: 10px;
  }
`
const PriceDescription = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #ccced1;
`

const InfoSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: .22;
  padding-left: 32px;
  justify-content: center;
  font-size: 19px;
  font-weight: 500;
`
const PriceSide = styled.div`
  display: flex;
  flex: .14;
  align-items: center;
  justify-content: center;
  background-color: #1f1f1f;
  font-size: 19px;
  font-weight: 500;

  span {
    font-size: 15px;
    margin-left: 2px;
    margin-top: 3px;
  }
`

const PriceDetailButton = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 2px 12px;
  margin-top: 20px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 100px;
  color: #b9c0ca;
  background-color: #1F1F1F;
  transition: .3s;

  :hover {
    background-color: #292929;
    transition: .3s ease;
  }
`
const PriceDetailWrapper = styled.div`
  display: flex;
  flex: 1;
`
const PriceDetail = styled.div`
  display: flex;
  flex: .8675;
  flex-direction: column;
  color: #b9c0ca;
  font-size: 13px;
  padding-bottom: 20px;

  div {
    flex: .15;
  }
`
const PriceDetailHead = styled.div`
  display: flex;
  font-weight: 800;
  padding-bottom: 5px;
`
const PriceDetailBody = styled.div`
  display: flex;
  padding-bottom: 5px;
`
const TuningSide = styled.div`
  display: flex;
  flex: .31;
  padding-left: 32px;
  align-items: center;
`
