import ContentBlock from "../../components/UI/ContentBlock"
import Card from "../../components/UI/Card"
import ContentHeader from "../../components/UI/ContentHeader"
import ContentTitle from "../../components/UI/ContentTitle"
import ContentDescription from "../../components/UI/ContentDescription"
import Icon from "../../components/Icon/Icon"
import {faLeaf} from "@fortawesome/pro-solid-svg-icons"
import ReadyToStart from "../../components/UI/ReadyToStart"
import {Anchor} from "antd"
import styled from "styled-components"
import {useEffect} from "react"

const MonitoringScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Мониторинг транспорта, исполнения заказов'
	}, [])

	return (
		<>
			<ContentHeader
				media="/assets/img/png/monitoring/header.png"
				orientation="left"
				title="Мониторинг"
				description="Местоположение, время посещений, скоростной режим, контроль топлива — все в одном месте."
			/>
			<Menu>
				<Anchor className="horizontal-menu-anchor"
						direction="horizontal"
						targetOffset={140}
						items={[
							{
								key: 'terminal',
								href: '#terminal',
								title: 'Терминал'
							},
							{
								key: 'logs',
								href: '#logs',
								title: 'Журнал событий'
							},
							{
								key: 'divisions',
								href: '#divisions',
								title: 'Филиалы'
							},
							{
								key: 'archive',
								href: '#archive',
								title: 'Архив маршрутных листов'
							},
							{
								key: 'external-sensors',
								href: '#external-sensors',
								title: 'Внешние датчики'
							},
							{
								key: 'eco-drive',
								href: '#eco-drive',
								title: <><Icon name={faLeaf} size={14} color="#7cb305" right={2}/> ECO Drive</>
							}
						]}
				/>
			</Menu>

			<ContentBlock>
				<Card id="terminal" title="Терминал" image="/assets/img/png/monitoring/terminal.png">
					<p>
						Настоящий ситуационный центр. Под рукой вся необходимая информация.
					</p>
					<p>
						С помощью быстрого поиска и аналитики, логист моментально получает полную информацию о точке,
						водителе или транспортном средстве.
					</p>
				</Card>

				<Card id="logs" title="Журнал событий" right image="/assets/img/png/monitoring/logs.png">
					<p>Мы собираем каждое действие пользователей и бережно его храним в течении трёх лет.</p>
					<p>Для удобного просмотра все данные сгруппированны по маршрутным листам и точкам.</p>
				</Card>

				<Card id="divisions" title="Поддержка филиалов" image="/assets/img/png/monitoring/divisions.png">
					<p>
						Перемещайтесь между вашими филиалами одним кликом мыши.
					</p>
					<p>
						Каждый филиал может иметь индивидуальные настройки пользователей, список водителей и ТС,
						часового пояса, систем мотивации и много другого.
					</p>
				</Card>

				<Card id="archive" title="Архив маршрутных листов" right image="/assets/img/png/monitoring/archive.png">
					<p>Каждый маршрут мы просчитываем по дорогам, поэтому у нас есть детальная информация о них:</p>
					<p>
						<ul>
							<li>Время выезда со склада</li>
							<li>Плановое время посещения каждой точки</li>
							<li>Расстояния всех отрезков</li>
							<li>Время возвращения на склад</li>
						</ul>
					</p>
				</Card>
			</ContentBlock>

			<ContentBlock id="external-sensors" bright top={110}>
				<ContentTitle>
					Внешние устройства и датчики
				</ContentTitle>
				<ContentDescription>
					Ознакомьтесь с дополнительными возможностями при установке внешних датчиков.
					Свяжитесь с нами, чтобы узнать, как получить датчики и их установку абсолютно бесплатно.
				</ContentDescription>

				<Card id="gps-sensor" title="GPS мониторинг" right image="/assets/img/png/monitoring/gps.png">
					<p>
						Является базовым устройством для подключения дополнительных внешних датчиков.
					</p>
					<p>
						Обладает точным
						местоположением, высокой частотой обновления, автоматическим проставлением выполнения заданий и
						многим другим.
					</p>
				</Card>

				<Card id="fuel-sensor" title="Контроль расхода топлива"
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd5447a0ca3830755bac_img_3.webp">
					<p>
						Подключите датчик уровня топлива (ДУТ) для точного измерения расхода, а мы оповестим вас о
						сливах горючего прямо на карте.
					</p>
				</Card>

				<Card id="temp-sensor" title="Температурный режим"
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd54bf8e2497f51aa2fe_img_2.webp"
					  right>
					<p>
						Контролируйте температурный режим в рефрижераторах во время доставки охлажденных и замороженных
						продуктов.
					</p>
					<p>
						Для сотрудников склада, помимо стандартной информации о готовности автомобиля к загрузке, будет
						доступна информация о текущей температуре в кузове.
					</p>
				</Card>

				<Card id="door-sensor" title="Датчик открытия ворот"
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd5447a0ca3830755bac_img_3.webp"
				>
					<p>Мы оповестим о любом открытии ворот вне зон загрузки и загрузки автомобиля.</p>
				</Card>

				<Card id="can-bus" title="CAN шина" right image="/assets/img/png/monitoring/can.png">
					<p>
						Для определенных моделей автомобилей мы можем получать точные данные о текущем пробеге и
						техническом состоянии.
					</p>
					<p>
						Эта информация используется для оповещения о предстоящем ТО в блоке <a>технического
						обслуживаения
						авто
					</a>.
					</p>
				</Card>

				<Card id="eco-drive" title={<><Icon name={faLeaf} size={32} color="#7cb305" right={5}/> ECO Drive</>}
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd54261a6f56031675a8_img_5.webp"
				>
					<p>
						Мы оцениваем стиль вождения каждого водителя и устанавливаем индекс экономичности, используя
						встроенные датчики ускорения и скорости.
					</p>
				</Card>
			</ContentBlock>
			<ContentBlock>
				<ReadyToStart/>
			</ContentBlock>
		</>
	)
}

export default MonitoringScreen

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 62px;
  width: 100%;
  background: #161A1D;
  height: 49px;
`
