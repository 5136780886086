import React from "react"
import styled from "styled-components"
import {faLocationArrow} from "@fortawesome/pro-solid-svg-icons/faLocationArrow"
import SectionTitle from "./SectionTitle"
import SectionItem from "./SectionItem"

const Monitoring = ({monthlyRoutes, changeMonthlyRoutes, offices, changeOffices, sensors, changeSensors}) => {

	const priceMonthlyRoutes = {
		head: ['Порядковый номер', 'Стоимость, ед.'],
		data: [
			{range: '< 500', price: 40},
			{range: '501 − 900', price: 30},
			{range: '901 − 1800', price: 25},
			{range: '1801 − 3000', price: 21},
			{range: '3001 − 6000', price: 18},
			{range: '6001 − 9000', price: 15},
			{range: '9001 − 12000', price: 11},
			{range: '12001 − 18000', price: 8},
			{range: '18000 +', price: 'обсуждается'}
		]
	}

	const priceOffices = {
		head: ['Количество', 'Стоимость, ед.'],
		data: [
			{range: 'До 5', price: 0},
			{range: '5 − 50', price: 500}
		]
	}

	const priceSensors = {
		head: ['Номер авто по счету', 'Стоимость, мес'],
		data: [
			{range: '1 − 15', price: 1200},
			{range: '16 − 30', price: 1000},
			{range: '31 − 60', price: 840},
			{range: '61 − 100', price: 705},
			{range: '101 − 200', price: 590},
			{range: '201 − 300', price: 495},
			{range: '301 − 400', price: 415},
			{range: '401 − 600', price: 348},
			{range: '> 600', price: 'обсуждается'}
		]
	}

	return (
		<Wrapper id="monitoring">
			<SectionTitle title="Мониторинг" icon={{backgroundColor: '#3195FF', component: faLocationArrow}}/>
			<SectionItem
				title="Маршрутных листов в месяц"
				slider={{
					min: 500,
					max: 18000,
					marks: {1200: '1 200', 3500: '3 500', 6000: '6k', 10000: '10k', 15000: '15k'},
					step: 100,
					onChange: changeMonthlyRoutes,
					value: monthlyRoutes.count
				}}
				info={monthlyRoutes.count}
				price={monthlyRoutes.price}
				priceDetail={priceMonthlyRoutes}
				priceDescription={'Количество МЛ в месяц'}
				description="Это базовый показатель для определения ежемесячной стоимости."
			/>

			<SectionItem
				title="Количество филиалов"
				slider={{
					min: 1,
					max: 50,
					marks: {5: '5', 10: '10', 20: '20'},
					step: 1,
					onChange: changeOffices,
					value: offices.count
				}}
				info={offices.count}
				price={offices.price}
				priceDetail={priceOffices}
				priceDescription={'Количество филиалов'}
				description="Укажите количество фалиалов. Каждый филиал может иметь собственные настройки."
			/>

			<SectionItem
				title="Дополнительные датчики"
				slider={{
					min: 0,
					max: 600,
					marks: {50: '50', 150: '150', 300: '300', 500: '500'},
					step: 1,
					onChange: changeSensors,
					value: sensors.count
				}}
				info={sensors.count}
				price={sensors.price}
				priceDetail={priceSensors}
				priceDescription={'Количество автомобилей'}
				description="Укажите количество автомобилей, оснащенных дополнительными датчиками GPS, ДУТ, температуры, открытия ворот или CAN шины."
			/>
		</Wrapper>
	)
}

export default Monitoring

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 110px;
`
