import styled from "styled-components"

const ContentDescription = ({centered = true, top = 20, children}) => {

	return (
		<Wrapper centered top={top}>
			<Content centered={centered}>
				{children}
			</Content>
		</Wrapper>
	)
}

export default ContentDescription

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.top}px;
  width: 100%;
  justify-content: center;
  align-items: ${props => props.centered ? 'center' : 'flex-start'};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 730px;
  padding-inline: 20px;
  align-items: ${props => props.centered ? 'center' : 'unset'};
  text-align: ${props => props.centered ? 'center' : 'unset'};
  font-weight: 300;
  color: ${props => props.theme.colorTextDescription};
  font-size: 20px;
  line-height: 32px;
`
