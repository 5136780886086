import styled from "styled-components"

const ContentBlock = ({centered = false, bright = false, top = 0, children, ...props}) => {

	return (
		<Wrapper centered bright={bright} top={top} {...props}>
			<Content centered={centered}>
				{children}
			</Content>
		</Wrapper>
	)
}

export default ContentBlock

const Wrapper = styled.div`
  display: flex;
  margin-top: ${props => props.top}px;
  padding-bottom: ${props => props.top}px;
  width: 100%;
  justify-content: center;
  align-items: ${props => props.centered ? 'center' : 'flex-start'};
  background-color: ${props => props.bright ? props.theme.colorBgCard : "unset"};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  padding-inline: 20px;
  align-items: ${props => props.centered ? 'center' : 'unset'};
  text-align: ${props => props.centered ? 'center' : 'unset'};
`
