import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

const Icon = ({size = 14, right = 0, left = 0, name, color, style, ...props}) => {

	return (
		<FontAwesomeIcon
			icon={name}
			color={color ?? "unset"}
			style={{...style, ...{width: size, height: size, paddingLeft: left, paddingRight: right}}}
			{...props}
		/>
	)
}
export default Icon
