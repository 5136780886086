export default {
	light: {
		theme: 'light',
		colorBgContainer: '#FFFFFF',
		colorBgCard: '#F7F9FC',
		colorTextDescription: '#616d72'
	},
	dark: {
		theme: 'dark',
		colorBgContainer: '#121212',
		colorBgCard: '#181c20',
		colorTextDescription: '#A0AABA',
		//colorLink: '#007afc',
		colorLinkHover: '#1668DC',
		colorText: 'rgba(255, 255, 255, 0.95)'
	}
}
