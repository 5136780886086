import AppNavigation from "./navigation"
import {BrowserRouter} from "react-router-dom"
import {ConfigProvider, theme} from "antd"
import "./index.css"
import {useState} from "react"
import Styled from "./inizialize/Styled"
import appTheme from "./theme"

const App = () => {


	const [currentTheme, setCurrentTheme] = useState('dark')

	const {defaultAlgorithm, darkAlgorithm} = theme

	return (
		<BrowserRouter>
			<ConfigProvider
				theme={{
					...{token: appTheme[currentTheme]}, ...{algorithm: currentTheme === 'dark' ? darkAlgorithm : defaultAlgorithm}
				}}
			>
				<Styled>
					<AppNavigation/>
				</Styled>
			</ConfigProvider>
		</BrowserRouter>
	)
}

export default App


