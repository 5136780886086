import styled from "styled-components"
import ContentBlock from "../../components/UI/ContentBlock"

const ServicesScreen = () => {

	return (
		<ContentBlock top={120}>


			<Wrapper>
				123123
				{/*<TransparentLayer/>*/}
			</Wrapper>


		</ContentBlock>
	)
}

export default ServicesScreen

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  /*background: #1E2328;*/
  border-radius: 16px;
  border: solid 1px #333943;
  background-image: radial-gradient(ellipse farthest-corner at 45px 45px, rgba(101, 67, 66, .15) 0%, rgba(80, 80, 80, 0.2));
`
